.page-heading-wrap {
  margin-top: 158px;
}

.accordion-content li {
  color: rgb(51, 51, 51);
}

.accordion-content input[type='radio'] {
  margin: 7px 0px 0px;
  float: left;
}

.accordion-content input[type='checkbox'] {
  margin: 7px 0px 0px;
  float: left;
}

.newsletter-form input[type='radio'] {
  margin: 7px 0px 0px;
  float: left;
}

.newsletter-form input[type='checkbox'] {
  margin: 7px 0px 0px;
  float: left;
}

.accordion-content .privacy.wpcf7-form-control-wrap {
  display: inline;
}

.newsletter-form .privacy.wpcf7-form-control-wrap {
  display: inline;
}

.textwidget .wpcf7 .wpcf7-form a {
  color: rgb(0, 94, 131);
  margin-top: 6px;
  margin-bottom: 6px;
  display: block;
}

.textwidget textarea {
  width: 100%;
}

.textwidget select {
  width: 100%;
}

.wpcf7-form .wpcf7-submit {
  margin-top: 16px;
}

.accordion-content .wpcf7-checkbox .wpcf7-list-item-label {
  padding-left: 15px;
  float: left;
}

.accordion-content .wpcf7-radio .wpcf7-list-item-label {
  padding-left: 15px;
  float: left;
}

.newsletter-form .wpcf7-checkbox .wpcf7-list-item-label {
  padding-left: 15px;
  float: left;
}

.newsletter-form .wpcf7-radio .wpcf7-list-item-label {
  padding-left: 15px;
  float: left;
}

.box-slider.tp-caption > h1 {
  line-height: 45px;
  font-size: 36px;
}

.box-slider.tp-caption {
  line-height: 45px;
  font-size: 36px;
}

.span12 h1 {
  line-height: 30px;
  font-size: 1.5em;
}

.span12 h2 {
  line-height: 30px;
  font-size: 1.5em;
}

.page-heading-wrap h2 {
  margin: 12px 0px;
  width: 70%;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 24px;
  font-size: 20px;
  display: inline-block;
}

.page-heading-wrap h1 {
  margin: 39px 0px;
  width: 70%;
  text-transform: uppercase;
  line-height: 24px;
  font-size: 20px;
  display: inline-block;
}

.light.bluebox-info-bar .info-bar-text a {
  font-size: 12px;
}

.bluebox-prime-nav #prime-nav > li > a {
  font-size: 16px;
  font-weight: 600;
}

.bluebox-prime-nav #prime-nav .sub-menu {
  background-color: rgba(0, 0, 0, 0.7);
}

.bluebox-prime-nav .prime-nav .sub-menu a {
  color: rgb(255, 255, 255);
  font-size: 13px;
  border-bottom-color: rgb(102, 102, 102);
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.bluebox-prime-nav #prime-nav li {
  margin: 0px;
}

ul.prime-nav > li > ul.sub-menu.bluebox-dropdown > li ul.sub-menu.bluebox-dropdown {
  left: -100%;
  right: 100%;
}

ul.prime-nav li > ul.bluebox-dropdown li:last-child > a {
  border-bottom-color: currentColor;
  border-bottom-width: medium;
  border-bottom-style: none;
}

ul.prime-nav li > ul.bluebox-dropdown li:last-child > li a {
  border-bottom-color: rgb(102, 102, 102);
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

a {
  -webkit-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
}

a:hover {
  -webkit-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
}

a.mobile-nav-button span {
  background-color: rgb(0, 94, 131);
}

.bluebox-builder-row {
  margin-bottom: 70px;
}

.page-content-layout-element .row h3 {
  line-height: 130%;
  margin-bottom: 20px;
}

.bluebox-accordion h3.accordion-heading {
  line-height: 300%;
  margin-top: 0px;
  margin-bottom: 0px;
}

.bluebox-accordion a {
  color: rgb(0, 94, 131);
}

.bluebox-accordion a:hover {
  color: rgb(41, 128, 185);
}

.accordion-long-title .bluebox-accordion h3.accordion-heading {
  line-height: 22px;
  padding-top: 10px;
  padding-bottom: 10px;
  display: block;
}

.accordion-long-title .bluebox-accordion .accordion-heading a.accordion-button {
  line-height: 60px;
}

.page-content-layout-element .row {
  line-height: 170%;
}

.page-content-layout-element li.gumm-scrollr-item {
  margin-bottom: 0px;
}

.page-content-layout-element a.type-1-1 {
  background: rgb(0, 94, 131);
  padding: 8px 8px 8px 50px;
  border-radius: 0px;
  border: currentColor;
  border-image: none;
  font-weight: 600;
  box-shadow: none;
}

.rich-content-layout-element a.type-1-1 {
  background: rgb(0, 94, 131);
  padding: 8px 8px 8px 50px;
  border-radius: 0px;
  border: currentColor;
  border-image: none;
  font-weight: 600;
  box-shadow: none;
}

.bluebox-info-bar a.type-1-1 {
  background: rgb(0, 94, 131);
  padding: 8px 8px 8px 50px;
  border-radius: 0px;
  border: currentColor;
  border-image: none;
  font-weight: 600;
  box-shadow: none;
}

a.type-1-1.button {
  background: rgb(0, 94, 131);
  padding: 8px 8px 8px 50px;
  border-radius: 0px;
  border: currentColor;
  border-image: none;
  font-weight: 600;
  box-shadow: none;
}

.page-content-layout-element a.type-1-1:hover {
  background-color: rgb(41, 128, 185);
}

.page-content-layout-element a.type-1-1:active {
  background-color: rgb(41, 128, 185);
}

.bluebox-info-bar a.small.type-1-1:hover {
  background-color: rgb(41, 128, 185);
}

.bluebox-info-bar a.small.type-1-1:active {
  background-color: rgb(41, 128, 185);
}

a.type-1-1.button:hover {
  background-color: rgb(41, 128, 185);
}

a.type-1-1.button:active {
  background-color: rgb(41, 128, 185);
}

.page-content-layout-element a.small.type-1-1 {
  padding: 0px 4px 1px 30px;
  font-weight: 400;
}

.bluebox-info-bar a.small.type-1-1 {
  padding: 2px 20px 3px 10px;
  font-weight: 400;
}

a.small.type-1-1.button {
  padding: 2px 20px 3px 10px;
  font-weight: 400;
}

.bluebox-info-bar .button {
  margin: 0px;
}

select {
  border-radius: 0px;
  border: 1px solid #ccc;
  border-image: none;
  box-shadow: none;
}

input[type='text'] {
  border-radius: 0px;
  border: 1px solid #ccc;
  border-image: none;
  box-shadow: none;
}

input[type='search'] {
  border-radius: 0px;
  border: 1px solid #ccc;
  border-image: none;
  box-shadow: none;
}

input[type='number'] {
  border-radius: 0px;
  border: 1px solid #ccc;
  border-image: none;
  box-shadow: none;
}

input[type='email'] {
  border-radius: 0px;
  border: 1px solid #ccc;
  border-image: none;
  box-shadow: none;
}

input[type='password'] {
  border-radius: 0px;
  border: 1px solid #ccc;
  border-image: none;
  box-shadow: none;
}

textarea {
  border: 1px solid #ccc;
  border-image: none;
}

input[type='text'] {
  border: 1px solid #ccc;
  border-image: none;
}

input[type='password'] {
  border: 1px solid #ccc;
  border-image: none;
}

input[type='datetime'] {
  border: 1px solid #ccc;
  border-image: none;
}

input[type='datetime-local'] {
  border: 1px solid #ccc;
  border-image: none;
}

input[type='date'] {
  border: 1px solid #ccc;
  border-image: none;
}

input[type='month'] {
  border: 1px solid #ccc;
  border-image: none;
}

input[type='time'] {
  border: 1px solid #ccc;
  border-image: none;
}

input[type='week'] {
  border: 1px solid #ccc;
  border-image: none;
}

input[type='number'] {
  border: 1px solid #ccc;
  border-image: none;
}

input[type='email'] {
  border: 1px solid #ccc;
  border-image: none;
}

input[type='url'] {
  border: 1px solid #ccc;
  border-image: none;
}

input[type='search'] {
  border: 1px solid #ccc;
  border-image: none;
}

input[type='tel'] {
  border: 1px solid #ccc;
  border-image: none;
}

input[type='color'] {
  border: 1px solid #ccc;
  border-image: none;
}

.uneditable-input {
  border: 1px solid #ccc;
  border-image: none;
}

select {
  border: 1px solid #ccc;
  border-image: none;
}

.dropdown-menu {
  border-radius: 0px;
}

.csr-form.well {
  border-radius: 0px;
  border-top-color: rgb(150, 184, 14);
  border-top-width: 10px;
  border-top-style: solid;
  box-shadow: none;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
}

.csr-form .span6 select {
  padding: 0px 6px;
}

.csr-form .span6 input {
  width: 80%;
}

.csr-form .span6 textarea {
  width: 80%;
}

.csr-form .span6 .uneditable-input {
  width: 80%;
}

.csr-form .span6 input[type='text'] {
  width: 80%;
}

.csr-form .span6 input[type='number'] {
  width: 80%;
}

.csr-form .span6 input[type='email'] {
  width: 80%;
}

.csr-form .span6 input[type='password'] {
  width: 80%;
}

.csr-form > div.wpcf7 {
  padding-top: 30px;
  padding-left: 30px;
}

.csr-form textarea {
  width: 90%;
}

textarea[readonly] {
  height: 150px;
  font-size: smaller;
  cursor: default;
  background-color: rgb(255, 255, 255);
}

.csr-form .wpcf7-list-item input[type='checkbox'] {
  margin-right: 40px;
  margin-left: 10px;
}

.csr-form .wpcf7-checkbox .first .wpcf7-list-item-label {
  margin-left: 0px;
}

.csr-form .wpcf7-checkbox .wpcf7-list-item-label {
  margin-left: 20px;
}

.csr-form > p {
  font-size: smaller;
}

.csr-form input[type='radio'] {
  width: 70px;
}

.csr-form .wpcf7-radio .wpcf7-list-item {
  margin: 0px 50px 10px;
}

.csr-form select {
  min-height: 45px;
}

input[type='radio'] {
  vertical-align: text-top;
}

input[type='checkbox'] {
  vertical-align: text-top;
}

input[type='submit'] {
  background: rgb(0, 94, 131);
  margin: 6px 0px 0px;
  padding: 8px 20px;
  border-radius: 0px;
  border: currentColor;
  border-image: none;
  color: rgb(255, 255, 255);
  font-weight: 600;
  box-shadow: none;
}

input[type='submit']:hover {
  background-color: rgb(41, 128, 185);
}

div.wpcf7-mail-sent-ok {
  padding: 20px;
  border: 1px solid #ccc !important;
  border-image: none !important;
  background-color: rgb(249, 249, 249) !important;
}

div.wpcf7-validation-errors {
  padding: 20px;
  border: 1px solid #ccc !important;
  border-image: none !important;
  background-color: rgb(249, 249, 249) !important;
}

div.wpcf7-mail-sent-ng {
  padding: 20px;
  border: 1px solid #ccc !important;
  border-image: none !important;
  background-color: rgb(249, 249, 249) !important;
}

div.wpcf7-mail-sent-ok {
  color: rgb(57, 143, 20);
}

div.wpcf7-validation-errors {
  color: red;
}

div.wpcf7-mail-sent-ng {
  color: red;
}

.newsletter-form div.wpcf7-mail-sent-ng {
  color: rgb(57, 143, 20);
}

.csr-form textarea {
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
}

.bluebox-footer-content::after {
  border: currentColor;
  border-image: none;
}

.bluebox-container ul {
  list-style-image: url("../images/arrow-ul-list-blue.gif");
}

.bluebox-container ul ul {
  list-style-image: url("../images/arrow-ul-list-gray.gif");
}

.bluebox-container .row div.msg {
  background: rgb(249, 249, 249);
  border: 1px solid rgb(234, 234, 234);
  border-image: none;
}

.bluebox-container .row div.msg p {
  margin: 0px;
}

.bluebox-container .row div.msg input[type='text'] {
  width: 100%;
}

.bluebox-container .row div.msg input[type='password'] {
  width: 100%;
}

.bluebox-container .row div.msg input[type='email'] {
  width: 100%;
}

.wpcf7-form-control-wrap {
  clear: both;
  display: block;
}

.wpcf7-form-control-wrap .wpcf7-list-item {
  margin: 0px;
  padding: 0px;
}

.bluebox-footer-content :last-child.widget-wrap .bluebox-details-social {
  width: 100%;
  text-align: right;
}

.bluebox-footer-content :last-child.widget-wrap .heading-wrap {
  float: right;
}

.bluebox-footer-content :last-child.widget-wrap .bluebox-details-social a {
  float: right;
}

.footer-wrap :first-child.span6 {
  width: 70%;
}

.footer-wrap :last-child.span6 {
  width: 25%;
  float: right;
}

/* HORIZONTAL SEARCH FORM on green backgroun */

.homeForm .titoloForm {
  margin-right: 20px;
}

.homeForm {
  width: 100%;
  padding: 20px 0;
  background-color: #B5CA41;
  margin-bottom: -40px;
  visibility: inherit;
}

.homeForm h1, .homeForm h3 {
  line-height: 1.1em;
  font-size: 1.4em;
  color: #FFF;
  margin: 0;
  font-weight: bold;
}

.homeForm .titoloForm {
  float: left;
}

.homeForm .ricercaAvanzata {
  margin-top: 10px;
  margin-right: 3%;
  float: right;
}

.homeForm .ricercaAvanzata a {
  color: #FFF;
}

.homeForm .divCampi {
  width: 40%;
  margin: 6px 0;
  float: left;
}

.homeForm .divBott {
  width: 18%;
  margin: 6px 0;
  float: left;
}

.homeForm input {
  width: 95%;
  padding: 8px 4px;
  border: 1px solid white;
  border-radius: 0;
  box-shadow: none;
}

.homeForm button {
  width: 95%;
  padding: 8px 4px;
  border: 1px solid white;
  border-radius: 0;
  color: #FFF;

  background: none;

  -webkit-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.homeForm .divBott button:hover {
  background-color: #96B80E;
  cursor: pointer;

  -webkit-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.clearfix {
  clear: both;
}

.homeBrand {
  margin: 0px;
  padding: 14px 0px;
  border: 1px solid #ccc;
  border-image: none;
  width: 100%;
  text-align: center;
}

.homeBrand img {
  margin: 0px 8px;
  padding: 0px;
  display: inline;
}

.linkBoxGrigio {
  padding: 8px 12px;
  border: 1px solid rgb(235, 235, 235);
  transition: 0.4s;
  border-image: none;
  clear: both;
  font-size: 13px;
  font-weight: 600;
  background-color: rgb(250, 250, 250);
  -webkit-transition: all .4s ease 0s;
  -moz-transition: all .4s ease 0s;
  -o-transition: all .4s ease 0s;
}

.linkBoxGrigio:hover {
  transition: 0.4s;
  background-color: rgb(245, 245, 245);
  -webkit-transition: all .4s ease 0s;
  -moz-transition: all .4s ease 0s;
  -o-transition: all .4s ease 0s;
}

.nav-tabs > li > a {
  border-radius: 0px;
  -webkit-border-radius: 0;
  -m-border-radius: 0;
}

.bluebox-tabs .tab-content p:last-child {
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.bluebox-tabs .nav {
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.bluebox-tabs .tab-content {
  padding: 16px 20px 18px;
  border: 1px solid rgb(233, 233, 233);
  border-image: none;
  box-shadow: inset 0px -3px 0px 0px rgba(0, 0, 0, 0.04);
  background-color: rgb(249, 249, 249);
  -webkit-box-shadow: inset 0 -3px 0 0 rgba(0, 0, 0, 0.04);
  -moz-box-shadow: inset 0 -3px 0 0 rgba(0, 0, 0, 0.04);
}

.bluebox-tabs .nav-tabs {
  border: 0px currentColor !important;
  border-image: none !important;
}

.nav-tabs > .active > a {
  border-width: 1px;
  border-style: solid;
  border-color: rgb(233, 233, 233) rgb(233, 233, 233) rgb(249, 249, 249);
  border-image: none;
  color: rgb(0, 94, 131);
  margin-right: 0px;
  box-shadow: none;
  background-color: rgb(249, 249, 249);
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
}

.nav-tabs > .active > a:hover {
  border-width: 1px;
  border-style: solid;
  border-color: rgb(233, 233, 233) rgb(233, 233, 233) rgb(249, 249, 249);
  border-image: none;
  color: rgb(0, 94, 131);
  margin-right: 0px;
  box-shadow: none;
  background-color: rgb(249, 249, 249);
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
}

.nav-tabs > .active > a > span {
  color: rgb(0, 94, 131);
}

.nav-tabs > .active > a:hover > span {
  color: rgb(0, 94, 131);
}

.nav-tabs > li > a {
  border-image: none;
  padding-right: 20px;
  padding-left: 36px;
  margin-right: 0px;
  border-top-color: rgb(233, 233, 233);
  border-right-color: rgb(233, 233, 233);
  border-bottom-color: rgb(233, 233, 233);
  border-left-color: currentColor !important;
  border-top-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-left-width: medium !important;
  border-top-style: solid;
  border-right-style: solid;
  border-bottom-style: solid;
  border-left-style: none !important;
  position: relative;
  box-shadow: inset 0px -3px 0px 0px rgba(0, 0, 0, 0.04);
  -webkit-box-shadow: inset 0 -3px 0 0 rgba(0, 0, 0, 0.04);
  -moz-box-shadow: inset 0 -3px 0 0 rgba(0, 0, 0, 0.04);
}

.nav-tabs > li > a:hover {
  border-image: none;
  padding-right: 20px;
  padding-left: 36px;
  margin-right: 0px;
  border-top-color: rgb(233, 233, 233);
  border-right-color: rgb(233, 233, 233);
  border-bottom-color: rgb(233, 233, 233);
  border-left-color: currentColor !important;
  border-top-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-left-width: medium !important;
  border-top-style: solid;
  border-right-style: solid;
  border-bottom-style: solid;
  border-left-style: none !important;
  position: relative;
  box-shadow: inset 0px -3px 0px 0px rgba(0, 0, 0, 0.04);
  -webkit-box-shadow: inset 0 -3px 0 0 rgba(0, 0, 0, 0.04);
  -moz-box-shadow: inset 0 -3px 0 0 rgba(0, 0, 0, 0.04);
}

.nav-tabs > li > a:hover {
  background-color: rgba(0, 0, 0, 0.02);
}

.nav-tabs > li > a > span {
  left: 20px;
  top: 50%;
  color: rgba(0, 0, 0, 0.26);
  font-size: 10px;
  margin-top: -4px;
  position: absolute;
}

.nav-tabs > li > a:hover > span {
  color: rgb(0, 94, 131);
}

.nav-tabs > li:first-child > a {
  border-left-color: rgb(233, 233, 233) !important;
  border-left-width: 1px !important;
  border-left-style: solid !important;
}

.nav-tabs > li:first-child > a:hover {
  border-left-color: rgb(233, 233, 233) !important;
  border-left-width: 1px !important;
  border-left-style: solid !important;
}

.bluebox-tabs .nav-tabs {
  list-style-image: none;
}

.nav-tabs li:last-child {
  margin-bottom: 0px;
}

.nav-tabs li {
  margin-bottom: 0px;
}

.current-cat {
  color: rgb(0, 96, 131);
  font-weight: bold;
}

.current-cat a {
  color: rgb(0, 96, 131);
  font-weight: bold;
}

.menu-aziende-che-assumono-container {
  margin-top: 40px;
}

.partner-layout-element .bluebox-partners {
  margin-top: 20px;
}

.gumm-layout-element-slider.partner-layout-element .heading-pagination.prev-next-heading-links {
  display: none;
}

.bluebox-partners ul.partners-slide li {
  min-height: 122px;
}

.bluebox-partners ul.partners-slide li a img {
  text-align: center;
  display: inline-block;
  opacity: 0.6 !important;
}

.bluebox-partners ul.partners-slide li a img:hover {
  opacity: 1 !important;
}

.partners-slide img:hover {
  filter: none;
  -webkit-filter: grayscale(0);
}

.homeBrand img:hover {
  filter: none;
  -webkit-filter: grayscale(0);
}

iframe {
  border: currentColor;
  border-image: none;
}

#mobile-menu {
  left: auto;
  right: 0px;
}

#mobile-menu::after {
  left: 0px;
  right: auto;
}

#mobile-menu .prime-nav-mobile-list li a {
  padding: 17px 35px 18px 30px;
}

.dropdown-menu a {
  white-space: normal;
}

.footer-wrap .span6 a:hover {
  color: rgb(255, 255, 255);
}

.verticalSearchForm option {
  color: rgb(0, 0, 0);
}

.verticalSearchForm select {
  color: rgb(85, 85, 85);
}

.verticalSearchForm option:first-child {
  color: rgb(85, 85, 85);
}

.bluebox-single-staff-wrap .bottom-staff-wrap .single-staff-left-wrap {
  padding-top: 0px;
}

.has-filterable-categories.gumm-layout-element-grid.staff-layout-element .tagline {
  margin-top: 20px;
}

.bluebox-staff .content-details {
  min-height: 146px;
}

.slick-track {
  margin: 0px auto;
}

.gmwd_markers_basic_box {
  border-bottom-color: rgb(204, 204, 204);
  border-bottom-width: 1px;
  border-bottom-style: dotted;
}

.gmwd_markers_basic_box {
  margin: 0px auto;
  max-width: 940px;
}

.wd-clear.container {
  margin: 0px auto;
  max-width: 940px;
}

.wd-clear.container .row {
  padding: 15px 15px 0px;
  margin-left: -15px;
}

.gmwd_marker_listing_basic_active {
  background: rgb(246, 246, 246);
  border-width: medium medium 1px !important;
  border-style: none none dotted !important;
  border-color: currentColor currentColor rgb(204, 204, 204) !important;
  border-image: none !important;
}

.wd-clear.container .row .col-xs-12.col-sm-8.col-md-8.col-lg-8 > p:nth-child(2).gmwd_marker_title1.gmwd_marker_title {
  display: none;
}

.gmwd_marker_picture {
  display: none;
}

@media all and (max-width: 768px) {
  .gmwd_marker_list_inside_map {
    display: none;
  }
  #slider img {
    height: 150px;
    width: 100%;
    object-fit: cover; // here
    -o-object-fit: cover;
  }
}

.wd-clear.gmwd_advanced_info_window_description1 p {
  font-size: 14px;
}

#cookie-notice .cookie-notice-container .bootstrap {
  background: rgb(181, 202, 65);
  border: currentColor;
  border-image: none;
  text-transform: uppercase;
}

#cookie-notice .cookie-notice-container .bootstrap:hover {
  background: rgb(148, 166, 47);
}

#cookie-notice .cookie-notice-container {
  padding: 20px;
}

@media all and (max-width: 979px) and (min-width: 447px) {
  .page-heading-wrap h6 {
    padding-left: 20px;
  }
  .page-heading-wrap h2 {
    padding-left: 20px;
  }
  .page-heading-wrap h1 {
    padding-left: 20px;
  }
}

@media only screen and (min-width: 768px) {
  .bluebox-info-bar {
    display: block !important;
  }
  .bluebox-info-bar .info-bar-all {
    display: block !important;
  }
  .bluebox-info-bar .info-bar-mobile {
    display: none !important;
  }
  .bluebox-heading-details {
    margin-top: 5px;
    margin-right: 10px;
    display: block !important;
  }
  .bluebox-heading-details .info-bar-all {
    display: block !important;
  }
  .bluebox-heading-details .info-bar-mobile {
    display: none !important;
  }
  .form-horizontal .control-label {
    text-align: left;
  }
}

@media only screen and (min-width: 979px) {
  a:focus {
    outline-offset: 0;
  }
  .bluebox-header > .span12 {
    position: fixed;
    z-index: 999;
    background-color: #FFF;
    box-shadow: 0px 1px 2px lightgrey;
    -moz-box-shadow: 0px 1px 2px lightgrey;
    -webkit-box-shadow: 0px 1px 2px lightgrey;
  }

  select {
    box-sizing: content-box;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    -ms-box-sizing: content-box;
  }
  .csr-form .span6 select {
    width: 80%;
  }
}

@media all and (max-width: 979px) {
  a.mobile-nav-button {
    left: auto;
    top: 50%;
    width: 60px;
    height: 30px;
    text-align: center;
    right: 0;
    padding-top: 10px;
    margin-top: -20px;
    display: inline-block;
    position: absolute;
    z-index: 300;
  }
  .bluebox-head-top {
    text-align: left;
  }
  .bluebox-head-top .bluebox-head-logo {
    margin-left: 40px;
    float: none;
    display: inline-block;
  }
  .footer-wrap .bluebox-copyrights-wrap {
    padding-left: 10px;
    padding-right: 10px;
  }

  .page-heading-wrap .bluebox-heading-details {
    margin-top: 5px;
  }
  .footer-wrap :last-child.span6 {
    float: left;
  }
  .footer-wrap :last-child.span6 div.gumm-social-networks-widget.widget-body {
    min-width: 190px;
  }
  a {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  .portfolio-layout-element .project-post-details h4 {
    min-height: 40px;
  }
  .csr-form .span6 select {
    width: 82.5%;
  }
}

@media only screen and (max-width: 768px) {
  .homeForm .bluebox-container {
    margin-left: 20px;
  }
  .homeForm .titoloForm {
    float: none;
  }
  .homeForm .ricercaAvanzata {
    float: none;
  }
  .homeForm .divCampi {
    width: 95%;
    float: none;
  }
  .homeForm .divBott {
    width: 50%;
    float: none;
  }
  .box-slider.tp-caption > h1 {
    line-height: 30px !important;
  }
  .box-slider.tp-caption {
    line-height: 30px !important;
  }
  .footer-wrap .span6 {
    width: 100%;
    text-align: left;
    float: none;
  }
  .footer-wrap :first-child.span6 {
    width: 100%;
    text-align: left;
    float: none;
  }
  .footer-wrap :last-child.span6 {
    width: 100%;
    text-align: left;
    float: none;
  }
  .bluebox-footer-content :last-child.widget-wrap .bluebox-details-social {
    width: 100%;
    text-align: left;
  }
  .bluebox-footer-content :last-child.widget-wrap .heading-wrap {
    float: left;
  }
  .bluebox-footer-content :last-child.widget-wrap .bluebox-details-social a {
    float: left;
  }
  .bluebox-head-top {
    text-align: left;
  }
  .bluebox-head-top .bluebox-head-logo {
    margin-left: 10px;
    float: none;
  }
  .nav-tabs > li > a {
    border-left-color: rgb(233, 233, 233) !important;
    border-left-width: 1px !important;
    border-left-style: solid !important;
  }
  .tabRisultatiRicerca .nav-tabs > li > a:hover {
    border-left-color: rgb(233, 233, 233) !important;
    border-left-width: 1px !important;
    border-left-style: solid !important;
  }
  .bluebox-prime-nav {
    display: block;
    visibility: visible;
  }
  #prime-nav {
    display: block;
    visibility: visible;
  }
  .bluebox-info-bar {
    display: block !important;
  }
  .bluebox-info-bar .info-bar-all {
    display: none !important;
  }
  .bluebox-info-bar .info-bar-mobile {
    display: block !important;
  }
  .bluebox-heading-details {
    margin-top: 5px;
    margin-right: 10px;
    display: block !important;
  }
  .bluebox-heading-details .info-bar-text {
    padding-right: 5px;
    display: block !important;
  }
  .bluebox-heading-details .info-bar-mobile {
    display: none !important;
  }

  .info-bar-mobile .btn-sm.btn {
    padding: 3px 9px 5px;
    margin-right: 0px;
    margin-left: 0px;
  }
  .bluebox-info-bar .info-bar-mobile a.small.type-1-1 {
    padding: 2px 10px 2px 2px;
  }
  .bluebox-head-logo img {
    width: 140px !important;
  }
  .csr-form .span6 select {
    width: 83.5%;
  }
}

@media all and (max-width: 470px) {
  .bluebox-info-bar {
    display: none !important;
  }
  .bluebox-info-bar .info-bar-all {
    display: none !important;
  }
  .bluebox-info-bar .info-bar-mobile {
    display: none !important;
  }
  .bluebox-heading-details {
    margin-top: 5px;
    display: block !important;
  }
  .bluebox-heading-details .info-bar-text {
    display: none !important;
  }
  .bluebox-heading-details .info-bar-mobile {
    display: block !important;
  }
  .btn-link {
    padding-left: 0;
  }
  .offers-pagination {
    font-size: 10px;
  }
  .pagination > li > a, .pagination > li > span {
    padding: 4px 8px;
  }
  .ggp-row a.columnJoboffers h1 {
    font-size: 1em;
  }
  .ggp-row a.columnJoboffers {
    font-size: 0.9em;
  }
  .ggp-row a.columnJoboffers:hover, .ggp-row a.columnJoboffers:focus {
    background-color: white;
  }
  .nav-tabs > li {
    float: none;
  }
}

@media all and (max-width: 447px) {
  .homeForm .form-inline {
    margin-left: 20px;
  }
  .homeForm .titoloForm {
    margin-left: 20px;
  }
  .homeForm .ricercaAvanzata {
    margin-left: 20px;
  }
  .bluebox-accordion h3.accordion-heading.head-link {
    line-height: 20px;
    padding-top: 10px;
  }
  .box-slider.tp-caption > h1 {
    line-height: 20px !important;
  }
  .box-slider.tp-caption {
    line-height: 20px !important;
  }
}

.sub-menu .menu-item a {
  font-size: 12px;
}

#login.info-bar-text {
  padding-right: 0;
}

.label {
  border-radius: 0;
}

.addthis_inline_share_toolbox {
  padding: 20px 0;
}